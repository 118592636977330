// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyASDw-1w1GbZ3tau4lDca4XMlNbjyDdcZo",
    authDomain: "invicare-5fb22.firebaseapp.com",
    projectId: "invicare-5fb22",
    storageBucket: "invicare-5fb22.appspot.com",
    messagingSenderId: "552290605084",
    appId: "1:552290605084:web:83a83361efcd82f0fb67d9",
    measurementId: "G-PZPSDDEE53"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
